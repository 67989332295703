import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../utils/api/api';
import { getCookie, removeCookie, setAuthCookie, setCookie } from '../../utils/helper/helper';

const initialState = {
  auth: {},
  abilities: localStorage.getItem('abilities') !== 'undefined' ? JSON.parse(localStorage.getItem('abilities')) : {},
  role: localStorage.getItem('role') ? localStorage.getItem('role') : {},
  authenticate: false,
  error: null,
  rememberMe: false,
  timezone: getCookie('timezone')};

var rememberMe = false;

export const authUser = createAsyncThunk('auth/Login', async (data) => {
  const response = await handleAPI('platform/auth/login', 'post', '', data);
  rememberMe = data.rememberMe;
  return response;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    Logout: (state) => {
      state.authenticate = false;
      localStorage.removeItem('role');
      localStorage.removeItem('abilities');
      removeCookie('token');
      removeCookie('email');
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
      setCookie('timezone', action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authUser.fulfilled, (state, action) => {
      state.auth = action.payload;
      state.role = action.payload.role;
      if (action.payload.role) {
        localStorage.setItem('role', action.payload.role);
      }
      if (action.payload.abilities) {
        localStorage.setItem('abilities', JSON.stringify(action.payload.abilities));
      }
      state.abilities = action.payload.abilities;
      state.authenticate = true;
      // if for some reason the login response doesn't include a timezone, set it to what's set in the browser
      const tZone = action.payload.timezone ? action.payload.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
      state.timezone = tZone
      setCookie('token', action.metaData.token);
      setCookie('email', action.payload.email);
      setCookie('username', action.payload.username);
      setAuthCookie('timezone', tZone, rememberMe);
    });
  },
});

export const { Logout, setTimezone } = authSlice.actions;

export default authSlice.reducer;
