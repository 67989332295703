import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { showToastr } from '../../redux/slices/toastrSlice';
import { getCookie } from '../../utils/helper/helper';
import { Logout } from '../../redux/slices/authSlice';

const useAxiosPrivate = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization'] && getCookie('token')) {
          config.headers['Authorization'] = getCookie('token');
        }
        return config;
      },
      (error) => console.error(error)
    );
    const responseInterceptor = axios.interceptors.response.use(
      async (response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data?.meta?.message !== undefined &&
          response.data?.meta?.message !== null
        ) {
          dispatch(
            showToastr({
              message: response.data?.meta?.message,
              visible: true,
              type: 'success',
            })
          );
        }
        return response;
      },
      async (error) => {
        dispatch(
          showToastr({
            message: error.response.data.error,
            visible: true,
            type: 'error',
          })
        );

        if (error.response.data.error.includes('Signature has expired')) {
          dispatch(Logout());
        }
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch]);
};

export default useAxiosPrivate;
