import { getUrlInfo } from "utils/helper/helper";

const { protocol, subdomain } = getUrlInfo();

export let BACKEND_URL;
if (process.env.REACT_APP_ENV === 'production') {
  BACKEND_URL = `${protocol}//${subdomain}${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_API_BASE_PATH}`;
} else {
  BACKEND_URL = `${protocol}//${subdomain}.${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_API_BASE_PATH}`;
}
