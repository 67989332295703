import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActionModal } from '../../../redux/slices/confirmationModalSlice';
import Modal from './Modal';

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const { heading, description, action, closeButtonText, actionButtonText } =
    useSelector((state) => state.actionModal);

  const handleOnSubmit = async () => {
    setSubmitDisabled(true);
    await action();
    dispatch(closeActionModal());
    setSubmitDisabled(false);
  };

  return (
    <Modal
      id='deleteModal'
      cancelButtonTitle={closeButtonText}
      submitButtonTitle={actionButtonText}
      onSubmit={handleOnSubmit}
      onClose={() => dispatch(closeActionModal())}
      submitButtonDisabled={submitDisabled}
      className='!max-w-[500px]'>
      <h2 className='text-text text-xl font-bold mb-4'>{heading}</h2>
      <p className='text-text text-base'>
        {description ?? 'Only an admin will be able to restore.'}
      </p>
    </Modal>
  );
};

export default ConfirmationModal;
